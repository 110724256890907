import { Injectable } from '@angular/core';

import { ApiServer } from '../models/Profile.model';
import { StateService } from '../state/state.service';

@Injectable({
  providedIn: 'root',
})
export class ServerService {
  constructor(private state: StateService) {}

  set(id: number, reload = true, full_reload = true) {
    const servers = this.state.get<ApiServer[]>('servers');
    const server = servers.find((x) => x.id == id);

    if (window && reload) {
      if (full_reload) {
        this.state.set({ selectedServer: server });
        window.location.reload();
      } else {
        this.state.set({ servers });
        this.state.set({ selectedServer: server });
      }
    }
  }
}
